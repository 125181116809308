<template>
  <a-layout-sider :trigger="null" collapsible v-model="isCollapsed" theme="light"
                  :style="{ top :0, paddingTop: '70px', overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
    <a-icon
      class="trigger"
      :type="isCollapsed ? 'menu-unfold' : 'menu-fold'"
      @click="onClickCollapse"
    />
    <a-menu theme="light" mode="inline" :defaultSelectedKeys="[menuSelected]" v-if="permissionAvailableName">
      <a-menu-item key="MARKET">
        <router-link to="/analytics">
          <a-icon type="shop"/>
          <span>Thị trường</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="SUBSCRIBE" @click="onClickSubscribe">
        <div>
          <a-icon type="shopping"/>
          <span>Theo dõi Shop</span>
        </div>
      </a-menu-item>
      <a-menu-item key="MARKET_GLOBAL" @click="onClickGlobalTrending">
        <div>
          <a-icon>
            <svg :height="iconSize" :width="iconSize" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: scale(1.2);">
              <path d="M25.6663 6.41699C25.6663 5.95286 25.482 5.50774 25.1538 5.17956C24.8256 4.85137 24.3805 4.66699 23.9163 4.66699H4.08301C3.61888 4.66699 3.17376 4.85137 2.84557 5.17956C2.51738 5.50774 2.33301 5.95286 2.33301 6.41699V11.667H25.6663V6.41699Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.39844 22.7678L9.5011 15.5928L13.3394 19.2678L18.0306 15.167L20.6439 17.715" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25.6667 10.5V21.5833C25.6667 22.0475 25.4823 22.4926 25.1541 22.8208C24.8259 23.149 24.3808 23.3333 23.9167 23.3333H7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.1484 8.16699H21.6484" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.48047 8.16699H7.64714" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.33301 10.5V15.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </a-icon>
          <span>Trending nước ngoài</span>
        </div>
      </a-menu-item>
      <a-menu-item key="PRICE_ALERT" @click="onClickPriceAlert">
        <div>
          <div>
            <a-icon>
              <svg
                :height="iconSize"
                :width="iconSize"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.3373 10.8463C21.9023 10.8463 21.549 10.5072 21.549 10.0896C21.549 7.19169 20.3738 4.46834 18.2398 2.41898C17.9318 2.12338 17.9318 1.64426 18.2398 1.34866C18.5477 1.05305 19.0469 1.05305 19.3553 1.34866C21.7865 3.68322 23.1257 6.78824 23.1257 10.0896C23.1257 10.5072 22.7724 10.8463 22.3373 10.8463Z"
                  fill="currentColor"
                />
                <path
                  d="M0.788376 10.8473C0.353291 10.8473 0 10.5082 0 10.0906C0 6.78925 1.33925 3.68423 3.77139 1.35061C4.07935 1.05501 4.57898 1.05501 4.88694 1.35061C5.1949 1.64621 5.1949 2.1258 4.88694 2.42141C2.75192 4.46935 1.57675 7.19269 1.57675 10.0906C1.57675 10.5082 1.22346 10.8473 0.788376 10.8473Z"
                  fill="currentColor"
                />
                <path
                  d="M11.562 24.2163C9.38805 24.2163 7.62012 22.5193 7.62012 20.4325C7.62012 20.0149 7.97341 19.6758 8.40849 19.6758C8.84358 19.6758 9.19687 20.0149 9.19687 20.4325C9.19687 21.6849 10.2572 22.7028 11.562 22.7028C12.8663 22.7028 13.9271 21.6849 13.9271 20.4325C13.9271 20.0149 14.2804 19.6758 14.7155 19.6758C15.1506 19.6758 15.5039 20.0149 15.5039 20.4325C15.5039 22.5193 13.7359 24.2163 11.562 24.2163Z"
                  fill="currentColor"
                />
                <path
                  d="M20.2359 21.1893H2.89163C1.87709 21.1893 1.05176 20.397 1.05176 19.4237C1.05176 18.9067 1.2863 18.4177 1.69527 18.0814C3.29419 16.785 4.20526 14.9002 4.20526 12.9034V10.0902C4.20526 6.19575 7.50609 3.02734 11.5638 3.02734C15.6209 3.02734 18.9218 6.19575 18.9218 10.0902V12.9034C18.9218 14.9002 19.8333 16.785 21.4214 18.0743C21.8407 18.4172 22.0753 18.9067 22.0753 19.4232C22.0753 20.397 21.2499 21.1893 20.2359 21.1893ZM11.5638 4.54084C8.37528 4.54084 5.78201 7.03006 5.78201 10.0902V12.9034C5.78201 15.3458 4.66695 17.6524 2.72311 19.2298C2.68616 19.26 2.62851 19.3225 2.62851 19.4237C2.62851 19.5608 2.74824 19.6758 2.89163 19.6758H20.2359C20.3788 19.6758 20.4985 19.5608 20.4985 19.4237C20.4985 19.3229 20.4409 19.26 20.4059 19.2321C18.4601 17.6529 17.345 15.3462 17.345 12.9034V10.0902C17.345 7.03006 14.7518 4.54084 11.5638 4.54084Z"
                  fill="currentColor"
                />
                <path
                  d="M11.5628 4.54048C11.1277 4.54048 10.7744 4.20136 10.7744 3.78373V0.756746C10.7744 0.339117 11.1277 0 11.5628 0C11.9979 0 12.3512 0.339117 12.3512 0.756746V3.78373C12.3512 4.20136 11.9979 4.54048 11.5628 4.54048Z"
                  fill="currentColor"
                />
              </svg>
            </a-icon>
            <span>Theo dõi giá</span>
          </div>
        </div>
      </a-menu-item>
      <a-menu-item key="SAVED_REPORT" @click="onClickSavedReport">
        <div>
          <a-icon>
            <IconSave :height="iconSize" :width="iconSize" class="mr-2" style="transform: scale(1.2);"/>
          </a-icon>
          <span>Báo cáo</span>
        </div>
      </a-menu-item>
      <a-menu-item key="USER">
        <router-link to="/user">
          <a-icon type="user"/>
          <span>Tài khoản</span>
        </router-link>
      </a-menu-item>
<!--      <a-menu-item key="SUPPORT" @click="onClickPopupSupport">-->
<!--        <a-icon type="customer-service"/>-->
<!--        <span>Hỗ trợ</span>-->
<!--      </a-menu-item>-->
<!--      <a-menu-item key="HELP">-->
<!--        <a :href="'http://hotro.metric.vn/'" target="_blank" rel="noopener nofollow">-->
<!--          <a-icon type="question-circle" />-->
<!--          <span>Hướng dẫn sử dụng</span>-->
<!--        </a>-->
<!--      </a-menu-item>-->
    </a-menu>
  </a-layout-sider>
</template>
<script>

import { MutationSidebar } from '@/store/modules/SidebarModule/mutation'
import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
import {mixinStoreGetterPermission, mixinStoreGetterUser} from '../../../mixin/store/MixinUser'
import {PERMISSION} from "@/constant/general/PermissionConstant";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {NOTIFICATION_TYPE} from "@/constant/general/GeneralConstant";
import IconSave from "@/assets/icon/IconSave";

export default {
  components: {
    IconSave
  },
  data() {
    return {
      PERMISSION: PERMISSION,
    }
  },
  methods: {
    async onClickCollapse() {
      await this.$store.commit(`SidebarModule/${MutationSidebar.isCollapsed}`, !this.isCollapsed)
    },
    async onClickPopupSupport() {
      // console.log('click popup support')
      await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSupport}`, true)
    },
    async onClickSubscribe() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        this.isLoadingDownloadListShop = false
        return
      }
      if (this.isHigherPriorityAccount(PERMISSION.market_standard.priority)) {
        await this.$router.push({name: 'subcribe_index_view'})
        return
      }
      await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
      return
    },
    async onClickGlobalTrending() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        this.isLoadingDownloadListShop = false
        return
      }
      if (this.isEqualAndHigherPriorityAccount(PERMISSION.market_advance.priority)) {
        await this.$router.push({name: 'market_global'})
        return
      }
      await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_permission)
      return
    },
    async onClickPriceAlert() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        this.isLoadingDownloadListShop = false
        return
      }
      if (this.isEqualAndHigherPriorityAccount(PERMISSION.marketv2_premium.priority)) {
        await this.$router.push({name: 'price_alert_view'})
        return
      }
      await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_company_permission)
      return
    },
    async onClickSavedReport() {
      if (!this.isLogin) {
        this.$router.push({ name: 'login' })
        this.isLoadingDownloadListShop = false
        return
      }
      if (!this.isStandardBAccount){
        if (!this.isAgencyOrHigherPriorityAccount || typeof PERMISSION[this.permissionAvailableName].limitSavedReport !== 'number') {
          await this.$store.dispatch(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupNotification}`, NOTIFICATION_TYPE.upgrade_company_permission)
          return
        }
      }
      await this.$router.push({name: 'saved_report_view'})
      return
    }
  },
  computed: {
    isCollapsed: {
      get() {
        return this.$store.getters[`SidebarModule/${MutationSidebar.isCollapsed}`]
      },
      async set(value) {
        await this.$store.commit(`SidebarModule/${MutationSidebar.isCollapsed}`, value)
      }
    },
    menuSelected: {
      get() {
        // console.log('change menuSelected = ', this.$store.state.SidebarModule.menuSelected)
        return this.$store.getters[`SidebarModule/${MutationSidebar.menuSelected}`]
      },
      async set(value) {
        await this.$store.commit(`SidebarModule/${MutationSidebar.menuSelected}`, value)
      }
    },
    iconSize() {
      return this.isCollapsed ? 14 : 12
    }
  },
  mixins: [
    mixinStoreGetterPermission,
    mixinStoreGetterGlobal,
    mixinStoreGetterUser
  ]
}
</script>
<style lang="scss">

#bee-default-layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#bee-default-layout .trigger:hover {
  /*color: #1890ff;*/
  color: $--color-primary;
}

</style>

const getUrlGPhotoSize = (urlImage, width = null,  height = null) => {
  if (!width && !height) {
    return urlImage
  }
  let urlImageWithSize = urlImage + '='
  if (width > 0 || width?.length > 0) {
    urlImageWithSize += `w${width}`
    if (height > 0 || height?.length > 0) {
      urlImageWithSize += `-h${height}`
    }
    return urlImageWithSize
  }
  if (height > 0 || height?.length > 0) {
    urlImageWithSize += `h${height}`
  }
  return urlImageWithSize
}
export {
  getUrlGPhotoSize
}

<template>
  <a-modal title=""
           :closable="true"
           :footer="null"
           v-model="showPopupPricing"
           :width="'80%'"
           style="max-width: 1120px"
           :maskClosable="true">
    <div class="page container-bg-white">
      <div class="columns is-mobile">
        <div class="d-sticker is-hidden-mobile">
          <img :src="urlSticker" alt="beecost-login" class="img-sticker">
        </div>

        <div class="popup-container">
          <h1 class="title">
            Nâng cấp tài khoản?
            <!--            <a-icon type="customer-service"/>-->
          </h1>
<!--          <h2 class="sub-title">Truy cập các tính năng cao cấp-->
<!--          </h2>-->
          <div class="voucher-section">
            <div class="advance-feature">
              <div>
                <p><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Phân tích theo từ khoá</p>
                <p><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Download danh sách sản phẩm</p>
                <p><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Sử dụng phân tích nâng cao</p>
                <p><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Được cập nhật tính năng mới liên tục</p>
              </div>
            </div>

            <div class="plan-container hide-scrollbar margin-top-15">
              <div class="plan" v-for="voucher in LIST_VOUCHER" :key="voucher.id">
                <div v-if="voucher.name" class="plan-name">{{voucher.name}}</div>
                <div class="plan-content-container">
                  <div v-if="voucher.content" class="plan-content">{{voucher.content}}</div>
                  <div v-if="voucher.sub_content" class="plan-sub-content">({{voucher.sub_content}})</div>
                </div>
                <div v-if="voucher.price" class="plan-pricing">{{voucher.price | formatCurrency}}</div>
                <a-button class="btn-buy" type="primary" size="large" @click="onCLickBuy(voucher.id)" :disabled="!voucher.isActive">
                  <span>{{voucher.btn_title}}</span>
                  <a-icon v-if="isCreatingVoucher && voucher.id === selectedVoucherId" type="loading"/>
                </a-button>
              </div>
            </div>
            <p style="text-align: center"><strong>Không phát sinh thêm bất kì chi phí nào trong quá trình sử dụng</strong></p>
          </div>
<!--          <a-divider />-->
<!--          <div class="d-flex support-container" style="justify-content: center">-->
<!--&lt;!&ndash;            <div class="title">Cần thêm trợ giúp?</div>&ndash;&gt;-->
<!--            <button type="button"-->
<!--                    style="align-items: center; display: flex;"-->
<!--                    class="btn-login"-->
<!--                    @click="onClickChatFacebook">-->
<!--              <IconMessenger style="width: 20px;height: auto; margin-right: 10px"/>-->
<!--              <span>{{selectedVoucherId ? 'Xác nhận chuyển khoản' : 'Đăng kí dùng thử' }}</span>-->
<!--            </button>-->
<!--          </div>-->

        </div>
      </div>
    </div>
    <p style="text-align: end; font-size: 12px">* Giá đã bao gồm VAT</p>
    <p style="text-align: end; font-size: 12px"><a-icon type="question-circle" style="margin-right: 4px"/><a  target="_blank" @click="gotoPaymentGuide()" style="color: unset; text-decoration: underline">Hướng dẫn thanh toán</a></p>
  </a-modal>
</template>

<script>
  import Default from '@/layout/Default'
  import { StickerConstants } from '@/constant/beecost/BeeCostResourceConstant'
  import { getUrlGPhotoSize } from '@/helper/GPhotoHelper'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import { MutationUser } from '@/store/modules/UserModule/mutation'
  import IconMessenger from '@/components/icon/IconMessenger'
  import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
  import {formatCurrency} from "@/helper/FormatHelper";
  import {createVoucher} from "@/service/payment/PaymentService";
  import {LIST_VOUCHER} from "@/constant/pricing/PricingPlansConstant";


  export default {
    components: {
      Default,
      IconMessenger
    },
    data() {
      return {
        visible: true,
        urlSticker: getUrlGPhotoSize(StickerConstants.introduction, '256'),
        LIST_VOUCHER: LIST_VOUCHER.filter(voucher => !voucher.isPromote),
        isCreatingVoucher: false
      }
    },
    beforeCreate() {
      this.showPopupPricing = false
      this.$router.push({name: 'pricing'})
    },
    async mounted() {
      // this.selectedVoucherId = null
    },
    methods: {
      gotoPaymentGuide(){
        this.showPopupPricing = false
        this.$router.push({name: 'payment_guide'})

      },
      async onClickChatFacebook() {
        window.open(`http://messenger.com/t/${MESSENGER_ID}`, '_blank')
      },
      async onCLickBuy(voucherId) {
        if (!this.isLogin) {
          // this.$notification['error']({
          //   message: 'Đăng nhập để tiếp tục',
          //   description: 'Chức năng này cần đăng nhập',
          // })
          this.$router.push({ name: 'login' })
          return
        }
        if (this.$route.name === 'payment' && this.selectedVoucherId === voucherId) {
          this.showPopupPricing = false
          return
        }
        if (this.selectedVoucherId !== voucherId) {
          this.isCreatingVoucher = true
          this.selectedVoucherId = voucherId
          let voucherItem = LIST_VOUCHER.find(item => voucherId === item.id)
          const voucher = await createVoucher(voucherItem.type)
          if (!voucher?.voucher_code) {
            this.$notification['error']({
              message: 'Tạo gói cước không thành công',
              description: 'Đã có lỗi xảy ra, vui lòng thử lại trong giây lát',
            })
            this.isCreatingVoucher = false
            return
          }
          await this.$store.dispatch(`UserModule/${MutationUser.setPercentPaymentProgress}`, 0)
          await this.$store.dispatch(`UserModule/${MutationUser.setIsSuccessfulPayment}`, null)
          await this.$store.dispatch(`UserModule/${MutationUser.setVoucherCode}`, voucher.voucher_code)
          await this.$store.dispatch(`UserModule/${MutationUser.paymentQRCode}`, voucher.payment_qrcode)
          this.isCreatingVoucher = false
        }
        this.showPopupPricing = false
        if (this.$route.name !== 'payment') {
          await this.$router.push({name: 'payment', query: {'voucherCode': this.voucherCode, 'voucherId': this.selectedVoucherId}})
        } else if (window) {
          await this.$router.push({query: {'voucherCode': this.voucherCode, 'voucherId': this.selectedVoucherId}})
          window.location.reload()
        }
      },
    },
    filters: {
      formatCurrency
    },
    computed: {
      showPopupPricing: {
        get() {
          return this.$store.state.GeneralModule.showPopupPricing
        },
        async set(value) {
          await this.$store.dispatch(`GeneralModule/${MutationGeneral.setShowPopupPricing}`, value)
        }
      },
      voucherCode: {
        get() {
          return this.$store.state.UserModule.voucherCode
        }
      },
      isLogin: {
        get() {
          return this.$store.state.UserModule.isLogin
        }
      },
      selectedVoucherId: {
        get() {
          return this.$store.state.UserModule.selectedVoucherId
        },
        async set(value) {
          await this.$store.dispatch(`UserModule/${MutationUser.setSelectedVoucherId}`, value)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .popup-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .advance-feature {
      font-size: 18px;
    }
  }

  .support-container {
    .title {
      font-size: 17px;
      margin-right: 10px;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hidden {
    height: 0;
  }

  .visible {
    height: unset;
  }

  .plan-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;

    .plan:not(:last-child) {
      margin-right: 20px;
    }

    .plan {
      height: 230px;
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $--color-primary;
      border-radius: 8px;
      padding: 12px;
      margin: 16px 0;

      .plan-name {
        font-size: 16px;
        font-weight: $--font-weight-primary;
      }

      .plan-content-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-grow: 1;
      }

      .plan-content {
        font-size: 30px;
        line-height: 34px;
        padding-bottom: 4px;
        color: $--color-primary;

      }

      .plan-sub-content {
        font-size: 16px;
        line-height: 18px;
      }

      .plan-pricing {
        font-weight: $--font-weight-primary;
        font-size: 20px;
        margin: 4px;
      }

      .btn-buy {
        width: 80%;

        span {
          text-transform: uppercase;
        }
      }
    }
  }

  .page {
    padding: 40px 20px 20px 20px;

    .d-sticker {
      margin-right: 20px;

      .img-sticker {
        height: auto;
        width: 120px;
        transform: scale(1.5);
      }

    }

    .btn-login {
      /*margin-top: 20px;*/
      min-height: 60px;
      color: #000000ab;
      font-weight: 500;
      font-size: 17px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
      font-weight: 500;
      border: none;

      background: white;
      padding: 15px 10px;
      cursor: pointer;

      .img-login-social {
        width: 40px;
        margin-right: 10px;
      }
    }
  }

  .text-highlight {
    color: $--color-primary;;
  }
</style>

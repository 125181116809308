<template>
  <a-modal title=""
           :closable="false"
           :footer="null"
           v-model="showPopupSystemError"
           :width="600">
    <div class="page container-bg-white">
      <div class="rows is-mobile">
        <h1 class="title">
          <a-icon type="notification" />
          <span class="title-text">Thông báo</span>:
        </h1>
        <div class="">
          <div class="content">
            <a class="main-content">Hệ thống đang gặp sự cố</a>
            <p>Xin vui lòng <a>kiểm tra lại đường truyền và thử lại</a> trong giây lát hoặc <a>liên hệ hỗ trợ</a></p>
            <br>
            <p class="">{{ BRAND_NAME }} chân thành xin lỗi và sẽ khắc phục sự cố sớm nhất</p>
          </div>
          <div class="container-action">
            <span class="text-action" @click="onClickChatFacebook">
              Liên hệ hỗ trợ
            </span>
            <div class="button-action">
              <a-button @click="onClickButton" size="large" type="primary">Thông báo lỗi cho {{ BRAND_NAME }}</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {BRAND_NAME, MESSENGER_ID} from "@/constant/general/GeneralConstant";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";

export default {
  name: "PopupSystemError",
  data() {
    return {
      BRAND_NAME: BRAND_NAME
    }
  },
  computed: {
    showPopupSystemError: {
      get() {
        return this.$store.state.GeneralModule.showPopupSystemError
      },
      set(newValue) {
        return this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSystemError}`, newValue)
      }
    },
  },
  methods: {
    onClickButton() {
      this.$notification['success']({
        message: `Thông báo cho ${BRAND_NAME} thành công`,
        description: 'Chúng tôi đã nhận được thông báo của bạn, hệ thống sẽ được kiểm tra và khắc phục sớm nhất',
      })
      this.showPopupSystemError = false
    },
    async onClickChatFacebook() {
      window.open(`https://messenger.com/t/${MESSENGER_ID}`, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.title-text {
  margin-left: 4px;
}

.content {
  margin-bottom: 24px;

  .main-content {
    font-size: 20px;
    color: $--color-primary;
  }

  .sub-content {
    width: 100%;
    text-align: end;

    a {
      color: gray;
      text-decoration: underline;
    }
  }
}

.button-action {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    margin-left: 10px;
  }

  .sub-text-btn {
    font-size: 10px;
  }
}

.text-action {
  cursor: pointer;
  text-decoration: underline;
}

.container-action {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
</style>

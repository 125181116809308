<template>

  <a-modal title=""
           :closable="true"
           :footer="null"
           v-model="showPopupContactSale"
           :maskClosable="true">
    <div class="page-login container-bg-white">
      <div class="columns is-mobile">
        <div class="d-sticker is-hidden-mobile">
          <img :src="urlSticker" alt="beecost-login" class="img-sticker">
        </div>

        <div class="">
          <h1 class="title">
            <a-icon type="customer-service"/>
            Liên hệ trực tiếp
          </h1>
          <h2 class="sub-title">
            Chúng tôi luôn luôn sẵn sàng
          </h2>
          <div class="">
          </div>
          <button type="button"
                  style="align-items: center; display: flex; margin-top: 20px;"
                  class="btn-login"
                  @click="onClickChatFacebook">
            <IconMessenger style="width: 50px;height: auto; margin-right: 10px"/>
            <span>Chat trên Facebook
          </span>
          </button>

        </div>
      </div>
    </div>
  </a-modal>


</template>

<script>
  import Default from '@/layout/Default'
  import { StickerConstants } from '@/constant/beecost/BeeCostResourceConstant'
  import { getUrlGPhotoSize } from '@/helper/GPhotoHelper'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import IconMessenger from '@/components/icon/IconMessenger'
  import {MESSENGER_ID} from "@/constant/general/GeneralConstant";


  export default {
    components: {
      Default,
      IconMessenger
    },
    data() {
      return {
        urlSticker: getUrlGPhotoSize(StickerConstants.chao_dong_chi, '200'),
      }
    },
    async mounted() {
      // this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupContactSale}`, true)
    },
    methods: {
      async onClickChatFacebook() {
        window.open(`http://messenger.com/t/${MESSENGER_ID}`, '_blank')
      }
    },
    computed: {

      showPopupContactSale: {
        get() {
          return this.$store.state.GeneralModule.showPopupContactSale
        },
        async set(value) {
          await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupContactSale}`, value)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .page-login {
    padding: 40px 20px;

    .d-sticker {
      margin-right: 20px;

      .img-sticker {
        height: auto;
        width: 100%;
      }

    }

    .btn-login {
      /*margin-top: 20px;*/
      min-height: 60px;
      color: #000000ab;
      font-weight: 500;
      font-size: 17px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
      font-weight: 500;
      border: none;

      background: white;
      padding: 15px 10px;
      cursor: pointer;

      .img-login-social {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
</style>

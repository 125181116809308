<template>
  <a-modal :title="title"
           :closable="true"
           :maskClosable="false"
           :footer="null"
           v-model="showPopup"
           @cancel="onClickCancel"
           :width="600">
    <div class="page container-bg-white">
      <div class="rows is-mobile ">
        <div class="content-container">
          <div class="content">
            <p class="main-content" :class="{'mobile-view' :isMobile}" v-for="(content, index) in contents" :key="index">{{ content }}</p>
          </div>
          <div class="button-action">
            <a-button @click="onClickOk" size="large" type="primary">{{okTitle}}</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "PopupNotification",
  props: {
    title: {
      type: String,
      required: true
    },
    contents: {
      type: Array,
      required: true
    },
    okTitle: {
      type: String,
      default: 'OK'
    }
  },
  methods: {
    onClickOk() {
      this.showPopup = ''
      this.$emit('ok')
    },
    onClickCancel() {
      this.$emit('cancel')
    }
  },
  computed: {
    showPopup: {
      get() {
        return !!this.$store.state.GeneralModule.showPopupNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
      }
    },
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style lang="scss" scoped>
.title-text {
  margin-left: 4px;
}

.content-container {

  .content {
    margin-bottom: 12px;
    margin-left: 22px;
    background-image: var(--url-background);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 160px;

    .main-content {
      font-size: 20px;
      line-height: 2.3rem;

      &.mobile-view {
        font-size: 16px;
        line-height: 2rem;
      }
    }

    .sub-content {
      width: 100%;
      text-align: end;

      a {
        color: gray;
        text-decoration: underline;
      }
    }
  }

  .button-action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      margin-left: 10px;
    }

    .sub-text-btn {
      font-size: 10px;
    }
  }
}

.text-highlight {
  color: $--color-primary;;
}
</style>

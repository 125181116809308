<template>
  <a-modal :closable="true"
           :maskClosable="false"
           :footer="null"
           v-model="showPopup"
           :width="550">
    <template v-slot:title>
      <div class="flex items-center" style="color: #F50000;">
        <IconRedError/>
        <p class="ml-2">Không có số liệu</p>
      </div>
    </template>
    <div class="page container-bg-white">
      <div class="rows is-mobile ">
        <div class="content-container">
          <div class="content text-xl" style="padding: 0 52px">
            <p>Chúng tôi không tìm thấy số liệu phù hợp.</p>
            <p>Vui lòng điều chỉnh tham số phân tích.</p>
            <div class="flex items-start w-full justify-center mt-4 text-primary-500 text-base">
              <IconIdea class=""/>
              <p class="ml-2" >
                Gợi ý: Loại bỏ Bộ lọc đơn ảo; hoặc tăng Thời gian phân tích;...
              </p>
            </div>
          </div>
          <div class="button-action mt-6">
            <a-button @click="onClickOk" size="large" type="primary">OK</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import IconRedError from "@/assets/icon/IconRedError";
import IconIdea from "@/assets/icon/IconIdea";

export default {
  name: "PopupEmptySearch",
  components: {
    IconRedError,
    IconIdea
  },
  methods: {
    onClickOk() {
      this.showPopup = ''
      this.$emit('ok')
    },
  },
  computed: {
    showPopup: {
      get() {
        return !!this.$store.state.GeneralModule.showPopupNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
      }
    },
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style lang="scss" scoped>
.title-text {
  margin-left: 4px;
}

.content-container {

  .content {
    background-image: var(--url-background);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 160px;

    .main-content {
      font-size: 20px;
      line-height: 2.3rem;

      &.mobile-view {
        font-size: 16px;
        line-height: 2rem;
      }
    }

    .sub-content {
      width: 100%;
      text-align: end;

      a {
        color: gray;
        text-decoration: underline;
      }
    }
  }

  .button-action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
      margin-left: 10px;
    }

    .sub-text-btn {
      font-size: 10px;
    }
  }
}

.text-highlight {
  color: $--color-primary;;
}
</style>

<template>
  <a-modal title=""
           :closable="true"
           :maskClosable="false"
           :footer="null"
           v-model="showPopupPromotedNotification"
           :width="500">
    <div class="page container-bg-white">
      <div class="columns is-mobile">
        <div class="d-sticker is-hidden-mobile">
          <img :src="urlSticker" alt="beecost-login" class="img-sticker">
        </div>

        <div class="">
          <h1 class="title">
            Khuyến mại dành cho bạn
          </h1>
          <h2 class="sub-title">
            <template v-if="promoteVoucher">
              Để lấy Gói Khuyến Mại cho riêng bạn.
            </template>
            <template v-else-if="marketReferralLink">
              <strong>Giới thiệu bạn bè - Nhận FREE gói Vip</strong>
            </template>
          </h2>

          <button type="button"
                  v-if="promoteVoucher"
                  style="align-items: center; display: flex; margin-top: 20px;"
                  class="btn-login"
                  @click="onClickChatFacebook">
            <IconMessenger style="width: 50px;height: auto; margin-right: 10px"/>
            <span>Chat với chúng tôi
          </span>
          </button>

          <div v-else style="width: 100%; text-align: right; margin-top: 24px">
            <a-button type="primary" size="large" @click="onClickViewReferralProgram">
              Xem chi tiết
            </a-button>
          </div>
          <div class="text-note" v-if="promoteVoucher">
            * Email của bạn có thể cũng được chuyển tới phiên chat.
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
<script>

import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {StickerConstants} from "@/constant/beecost/BeeCostResourceConstant";
import IconMessenger from "@/components/icon/IconMessenger";

export default {
  name: "PopupPromotedInfo",
  components: {
    IconMessenger
  },
  data() {
    return {
      urlSticker: getUrlGPhotoSize(StickerConstants.search, '128'),
    }
  },
  mounted() {

  },
  methods: {
    onClickChatFacebook() {
      window.open(this.promoteVoucher, '_blank')
    },
    onOpenLogin() {
      this.$router.push({ name: 'login' })
      // this.showPopupPromotedNotification = false
    },
    async onClickShowPricing() {
      await this.$router.push({name: 'pricing'})
    },
    async onClickViewReferralProgram() {
      this.$router.push({name: 'referral_program'})
      this.showPopupPromotedNotification = false

    },
  },
  computed: {
    showPopupPromotedNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupPromotedNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPromotedNotification}`, value)
      }
    },
    promoteVoucher: {
      get() {
        return this.$store.state.UserModule.promoteVoucher
      }
    },
    marketReferralLink: {
      get() {
        return this.$store.state.UserModule.marketReferralLink
      }
    },
    isLogin: {
      get() {
        return this.$store.state.UserModule.isLogin
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 40px 20px;

  .d-sticker {
    margin-right: 20px;

    .img-sticker {
      height: auto;
      width: 70%;
    }

  }

  .btn-login {
    /*margin-top: 20px;*/
    min-height: 60px;
    min-width: 300px;
    color: #000000ab;
    font-weight: 500;
    font-size: 17px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
    font-weight: 500;
    border: none;
    background: white;
    padding: 15px 10px;
    cursor: pointer;

    .img-login-social {
      width: 40px;
      margin-right: 10px;
    }
  }
}

.text-note {
  margin-top: 12px;
  font-size: 0.75rem;
}
</style>

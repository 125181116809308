<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.5" cy="10.5" r="10.5" fill="currentColor"/>
    <path d="M11.9446 14.7971C11.9446 15.6109 11.3096 16.2782 10.5001 16.2782C9.69058 16.2782 9.05566 15.6109 9.05566 14.7971C9.05566 13.9834 9.69058 13.3161 10.5001 13.3161C11.3096 13.3161 11.9446 13.9834 11.9446 14.7971ZM11.1621 11.835H9.82307C9.62459 11.835 9.46119 11.675 9.45214 11.4717L9.16868 5.1208C9.15899 4.90391 9.32788 4.72266 9.53962 4.72266H11.4597C11.6718 4.72266 11.8408 4.90448 11.8306 5.12167L11.533 11.4725C11.5235 11.6755 11.3602 11.835 11.1621 11.835Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "IconRedError"
}
</script>

<style scoped>

</style>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_2045_3646" fill="white">
      <path d="M8.0001 3.02734C5.49647 3.02734 3.45947 5.12227 3.45947 7.6975C3.45947 9.42466 4.38722 11.0009 5.84763 11.7978V13.3534C5.84763 13.6211 6.05864 13.8382 6.31897 13.8382H9.68123C9.94156 13.8382 10.1526 13.6213 10.1526 13.3534V11.8098C11.6128 10.9921 12.5406 9.41057 12.5406 7.6975C12.5406 5.12245 10.5037 3.02734 8.0001 3.02734ZM9.48405 11.0708C9.31703 11.15 9.20987 11.3219 9.20987 11.5112V12.8686H6.79033V11.495C6.79033 11.3035 6.68051 11.1298 6.51035 11.0521C5.22971 10.4666 4.4022 9.14975 4.4022 7.6975C4.4022 5.65701 6.01623 3.99693 8.00013 3.99693C9.98402 3.99693 11.5979 5.65701 11.5979 7.6975C11.5979 9.13827 10.7682 10.4624 9.48405 11.0708Z"/>
    </mask>
    <path d="M8.0001 3.02734C5.49647 3.02734 3.45947 5.12227 3.45947 7.6975C3.45947 9.42466 4.38722 11.0009 5.84763 11.7978V13.3534C5.84763 13.6211 6.05864 13.8382 6.31897 13.8382H9.68123C9.94156 13.8382 10.1526 13.6213 10.1526 13.3534V11.8098C11.6128 10.9921 12.5406 9.41057 12.5406 7.6975C12.5406 5.12245 10.5037 3.02734 8.0001 3.02734ZM9.48405 11.0708C9.31703 11.15 9.20987 11.3219 9.20987 11.5112V12.8686H6.79033V11.495C6.79033 11.3035 6.68051 11.1298 6.51035 11.0521C5.22971 10.4666 4.4022 9.14975 4.4022 7.6975C4.4022 5.65701 6.01623 3.99693 8.00013 3.99693C9.98402 3.99693 11.5979 5.65701 11.5979 7.6975C11.5979 9.13827 10.7682 10.4624 9.48405 11.0708Z" fill="currentColor" stroke="#FF8844" stroke-width="20" mask="url(#path-1-inside-1_2045_3646)"/>
    <mask id="path-2-inside-2_2045_3646" fill="white">
      <path d="M9.48062 14.2705H6.51893C6.2369 14.2705 6.0083 14.4641 6.0083 14.703C6.0083 14.9419 6.2369 15.1355 6.51893 15.1355H9.48062C9.76265 15.1355 9.99125 14.9419 9.99125 14.703C9.99128 14.4641 9.76268 14.2705 9.48062 14.2705Z"/>
    </mask>
    <path d="M9.48062 14.2705H6.51893C6.2369 14.2705 6.0083 14.4641 6.0083 14.703C6.0083 14.9419 6.2369 15.1355 6.51893 15.1355H9.48062C9.76265 15.1355 9.99125 14.9419 9.99125 14.703C9.99128 14.4641 9.76268 14.2705 9.48062 14.2705Z" fill="currentColor" stroke="#FF8844" stroke-width="20" mask="url(#path-2-inside-2_2045_3646)"/>
    <path d="M8.51054 2.04963V0.943257C8.51054 0.661239 8.28192 0.432617 7.9999 0.432617C7.71788 0.432617 7.48926 0.661239 7.48926 0.943258V2.04963C7.48926 2.33165 7.71788 2.56027 7.9999 2.56027C8.28192 2.56027 8.51054 2.33165 8.51054 2.04963Z" fill="currentColor"/>
    <path d="M2.10041 7.43848H0.994039C0.71202 7.43848 0.483398 7.6671 0.483398 7.94912C0.483398 8.23114 0.712021 8.45976 0.99404 8.45976H2.10041C2.38243 8.45976 2.61105 8.23114 2.61105 7.94912C2.61105 7.6671 2.38243 7.43848 2.10041 7.43848Z" fill="currentColor"/>
    <path d="M15.0057 7.43848H13.8993C13.6173 7.43848 13.3887 7.6671 13.3887 7.94912C13.3887 8.23114 13.6173 8.45976 13.8993 8.45976H15.0057C15.2877 8.45976 15.5163 8.23114 15.5163 7.94912C15.5163 7.6671 15.2877 7.43848 15.0057 7.43848Z" fill="currentColor"/>
    <path d="M3.46119 3.53958L2.67249 2.75975C2.47195 2.56146 2.14863 2.56329 1.95034 2.76384C1.75205 2.96438 1.75388 3.2877 1.95443 3.48598L2.74313 4.26581C2.94367 4.4641 3.26699 4.46227 3.46527 4.26173C3.66356 4.06118 3.66173 3.73787 3.46119 3.53958Z" fill="currentColor"/>
    <path d="M13.3266 2.76055L12.5379 3.54038C12.3374 3.73867 12.3355 4.06199 12.5338 4.26253C12.7321 4.46307 13.0554 4.4649 13.256 4.26662L14.0447 3.48679C14.2452 3.2885 14.2471 2.96518 14.0488 2.76464C13.8505 2.5641 13.5272 2.56227 13.3266 2.76055Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "IconIdea"
}
</script>

<style scoped>

</style>

import {mapActions, mapGetters} from "vuex";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import {PERMISSION} from "@/constant/general/PermissionConstant";

const mixinStoreGetterUser = {
  computed: {
    ...mapGetters({
      isLogin:  `${MutationUser.MODULE_PATH}/isLogin`,
      userProfile:  `${MutationUser.MODULE_PATH}/userProfile`,
      email:  `${MutationUser.MODULE_PATH}/email`,
      fullName:  `${MutationUser.MODULE_PATH}/fullName`,
      isInstallExtension:  `${MutationUser.MODULE_PATH}/isInstallExtension`,
      marketReferralLink:  `${MutationUser.MODULE_PATH}/marketReferralLink`,
    }),
    accountPlanName() {
      return PERMISSION[this.$store.state.UserModule[MutationUser.permissionPlanName]]?.label
    },
    accountName() {
      return PERMISSION[this.$store.state.UserModule[MutationUser.permissionAvailableName]]?.label
    },
  }
}

const mixinStoreGetterPayment = {
  computed: {
    ...mapGetters({
      inactiveVouchers:  `${MutationUser.MODULE_PATH}/inactiveVouchers`,
      promoteVoucher:  `${MutationUser.MODULE_PATH}/promoteVoucher`,
      selectedVoucherId:  `${MutationUser.MODULE_PATH}/selectedVoucherId`,
      voucherCode:  `${MutationUser.MODULE_PATH}/voucherCode`,
      paymentQRCode:  `${MutationUser.MODULE_PATH}/${MutationUser.paymentQRCode}`,
      percentPaymentProgress:  `${MutationUser.MODULE_PATH}/percentPaymentProgress`,
      isSuccessfulPayment:  `${MutationUser.MODULE_PATH}/isSuccessfulPayment`,
    })
  }
}

const mixinStoreGetterPermission = {
  computed: {
    ...mapGetters({
      isExpired:  `${MutationUser.MODULE_PATH}/isExpired`,
      expirationDate:  `${MutationUser.MODULE_PATH}/expirationDate`,
      freeSearchCount:  `${MutationUser.MODULE_PATH}/freeSearchCount`,
      freeSearchCountPerWeek:  `${MutationUser.MODULE_PATH}/${MutationUser.freeSearchCountPerWeek}`,
      remainProductDownloadCountPerWeek:  `${MutationUser.MODULE_PATH}/${MutationUser.remainProductDownloadCountPerWeek}`,
      remainShopDownloadCountPerWeek:  `${MutationUser.MODULE_PATH}/${MutationUser.remainShopDownloadCountPerWeek}`,
      permissionPlanName:  `${MutationUser.MODULE_PATH}/${MutationUser.permissionPlanName}`,
      permissionAvailableName:  `${MutationUser.MODULE_PATH}/${MutationUser.permissionAvailableName}`,
      isDefaultAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isDefaultAccountPlan}`,
      isBasicAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isBasicAccountPlan}`,
      isStandardAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isStandardAccountPlan}`,
      isAdvanceAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isAdvanceAccountPlan}`,
      isBusinessAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isBusinessAccountPlan}`,
      isCompanyAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isCompanyAccountPlan}`,
      isAgencyAccountPlan: `${MutationUser.MODULE_PATH}/${MutationUser.isAgencyAccountPlan}`,
      isDefaultAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isDefaultAccount}`,
      isBasicAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isBasicAccount}`,
      isStandardAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isStandardAccount}`,
      isStandardBAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isStandardBAccount}`,
      isAdvanceAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isAdvanceAccount}`,
      isBusinessAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isBusinessAccount}`,
      isCompanyAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isCompanyAccount}`,
      isAgencyOrHigherPriorityAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isAgencyOrHigherPriorityAccount}`,
      isPaidAccount: `${MutationUser.MODULE_PATH}/${MutationUser.isPaidAccount}`,
    }),
  },
  methods: {
    isHigherPriorityAccount(priority) {
      return PERMISSION[this[MutationUser.permissionAvailableName]]?.priority > priority
    },
    isEqualAndHigherPriorityAccount(priority) {
      return PERMISSION[this[MutationUser.permissionAvailableName]]?.priority >= priority
    }
  }
}

export {
  mixinStoreGetterUser,
  mixinStoreGetterPayment,
  mixinStoreGetterPermission,
}

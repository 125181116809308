import { http } from "@/plugin/http";
import { urlApiCreateLead } from "./LeadEndpoint";

const apiCreateLead = async options => {
  const urlApi = urlApiCreateLead();
  try {
    const response = await http.post(urlApi, options);
    if (response?.data != null) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

export { apiCreateLead };

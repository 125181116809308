<template>

  <a-modal title=""
           :closable="true"
           :footer="null"
           v-model="showPopupLogin" :maskClosable="false">
    <div class="page-login container-bg-white">
      <div class="columns is-mobile">
        <div class="d-sticker is-hidden-mobile">
          <img :src="urlStickerLogin" alt="beecost-login" class="img-sticker">
        </div>

        <div class="">
          <h1 class="title">
            <a-icon type="login"/>
            Xin hãy đăng nhập
          </h1>

          <h2 class="sub-title">
            Để sử dụng tính năng này
          </h2>
          <button type="button"
                  class="btn-login"
                  @click="onClickGoogleLogin">
            <img src="https://developers.google.com/identity/images/g-logo.png" alt="google"
                 class="img-responsive img-login-social">
            <span>Đăng nhập bằng Google</span>
          </button>

          <div class="margin-top-20 color-text-secondary">
            Bằng việc Đăng nhập/Đăng ký, bạn đồng ý với <a href="https://www.beecost.com/term"
                                                           target="_blank">Điều khoản sử
            dụng</a>
            của
            BeeCost.Com
          </div>

        </div>
      </div>
    </div>
  </a-modal>


</template>

<script>
  import { getParamsFromUrl } from '@/helper/UrlHelper'
  import { getLoginUrl, SocialLoginURIs } from '@/api/user/UserEndpoint'
  import { openPopupCenter } from '@/helper/WindowHelper'
  import Default from '@/layout/Default'
  import {Message, StickerConstants} from '@/constant/beecost/BeeCostResourceConstant'
  import { getUrlGPhotoSize } from '@/helper/GPhotoHelper'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import {updateProfileUserCurrent} from '@/service/user/UserProfileService'
  import {URL_DOWNLOAD_VAONET} from "@/config/ConfigEndpoint";


  export default {
    components: {
      Default
    },
    data() {
      return {
        visible: true,
        urlStickerLogin: getUrlGPhotoSize(StickerConstants.hi, '200'),
      }
    },
    async mounted() {
        this.$router.push({ name: 'login' })
      this.$router.push({query: {popup: 'login'}})
      let url = document.location.href
      const { state, code } = getParamsFromUrl(url)
      const urlLogin = getLoginUrl(state)
      // if (!urlLogin) {
      //   return
      // }
      // console.log('url', url)
      // console.log('state', state)
      // console.log('code', code)
      // console.log('urlLogin', urlLogin)

      // console.log('SocialLoginURIs.GOOGLE', SocialLoginURIs.GOOGLE)
      // let response = await http.post('https://api-web-market.beecost.vn/auth/google', {code})
      // console.log(response.data)
      // const accessTokenResponse = response.data
      // let accessToken = accessTokenResponse['access_token']
    },
    beforeDestroy() {
      this.$router.push({query: {popup: undefined}})
    },
    methods: {
      async onClickGoogleLogin() {

        // window.open(SocialLoginURIs.GOOGLE, '_blank')
        let newWindow = openPopupCenter(SocialLoginURIs.GOOGLE, '', 600, 600)
        window.addEventListener('message', receiveMessage, false)

        function receiveMessage(event) {
          if (event.data !== Message.USER_LOGGED_IN) {
            return
          }
          updateProfileUserCurrent()
            .then(userProfile => {
              console.log('userProfile', userProfile)
              window.removeEventListener('message', receiveMessage, false)
              newWindow.close()

              location.reload()
            })

          // more logic...
        }
      },
      onClickDownloadVaoNet() {
        document.location.href = URL_DOWNLOAD_VAONET
      }
    },
    computed: {

      showPopupLogin: {
        get() {
          return this.$store.state.GeneralModule.showPopupLogin
        },
        async set(value) {
          await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupLogin}`, value)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .page-login {
    padding: 40px 20px;

    .d-sticker {
      margin-right: 20px;

      .img-sticker {
        height: auto;
        width: 100%;
      }

    }

    .btn-login {
      /*margin-top: 20px;*/
      min-height: 60px;
      color: #000000ab;
      font-weight: 500;
      font-size: 17px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
      font-weight: 500;
      border: none;
      margin-top: 8px;
      background: white;
      padding: 15px 10px;
      cursor: pointer;

      .img-login-social {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
</style>

<template>

  <a-modal title="Nâng cấp Tài khoản"
           :closable="closable"
           :keyboard="closable"
           :footer="null"
           v-model="showPopupUpgradeAdvance"
           :maskClosable="false"
           :width="650">
    <div class="container-bg-white">
<!--      <p>Dùng mã Voucher để kích hoạt gói sử dụng</p>-->
      <p v-if="voucherItem && voucherItem.title">Nội dung: Gói <span v-if="voucherItem.type === '1d'">khuyến mại </span>"<span class="highlight">{{voucherItem.title}}</span>"</p>
      <br>
      <h2>Mã Voucher kích hoạt</h2>
      <div class="input-wrap d-flex flex-row">
        <a-input @keydown.enter="applyVoucher" type="input" class="field margin-right-5" v-model="voucherCode" :disabled="!!promoteVoucher"/>
        <a-button type="primary" @click="applyVoucher" v-if="!inactiveVouchers"><span style="color: white">Áp dụng</span></a-button>
        <div class="tooltip" v-if="inactiveVouchers">
          <a-button @click="onClickCopy(voucherCode, 'popup_upgrade_tooltip_input')">
            <span class="tooltiptext" id="popup_upgrade_tooltip_input">Đã sao chép</span>
            <span>Copy Mã gửi Người mới</span>
          </a-button>
        </div>
      </div>
      <div class="message-container">
        <p class="status success" v-if="isApplyVip">
          Tuyệt vời, bạn đã được Nâng cấp Tài khoản (kiểm tra <a @click="gotoProfile">trang tài khoản</a>)
        </p>
        <p v-else class="status error">
          {{messageStatusText}}
        </p>
        <p class="message-text">{{messageText}}</p>
        <p v-if="isApplyVip === false"><strong>Gợi ý cho bạn:</strong> Tham khảo các <a @click="onClickShowPricing">Gói sử dụng</a> khác.</p>
      </div>
      <br>
<!--      <div v-if="!$route.query['voucher']" class="d-flex flex-row" style="align-items: center">-->
<!--        <span style="font-size: 20px; color: #ff7227">Để mua Voucher:</span>-->
<!--        <button type="button"-->
<!--                style="align-items: center; display: flex; margin-left: 8px;"-->
<!--                class="btn-login"-->
<!--                @click="onClickChatFacebook">-->
<!--          <IconMessenger style="width: 50px;height: auto; margin-right: 10px"/>-->
<!--          <span>Chat trên Facebook-->
<!--          </span>-->
<!--        </button>-->
<!--      </div>-->
      <p style="text-align: right;">
        <a :href="`http://messenger.com/t/${MESSENGER_ID}`" target="_blank" rel="nofollow" style="color: #888">
          <a-icon type="question-circle" />
          Trợ giúp
        </a>
      </p>
<!--      <b v-if="inactiveVouchers">Cần thêm <span class="remain-user-to-active">{{inactiveVouchers.remain_user_to_active}} Người mới</span> Áp dụng mã để kích hoạt voucher</b>-->
<!--      <div class="margin-top-40" v-if="promoteVoucher || (!voucherCode && referralVoucher)">-->
<!--        <p style="font-size: 18px">Khuyến mại dành riêng cho bạn: <span class="show-more" @click="isFullVoucherDetail = true">Xem chi tiết</span></p>-->
<!--        <div class="voucher-information" :class="{'show-detail': isFullVoucherDetail}">-->
<!--          <p class="tooltip">Mã Voucher: <span class="voucher-code">{{ promoteVoucher || referralVoucher.voucher }}</span>-->
<!--            <a-button @click="onClickCopy(promoteVoucher || referralVoucher.voucher, 'popup_upgrade_tooltip')" size="small">-->
<!--              <span class="tooltiptext" id="popup_upgrade_tooltip">Đã sao chép</span>-->
<!--              <span>Copy Mã <span v-if="referralVoucher">gửi Người mới</span></span>-->
<!--            </a-button>-->
<!--          </p>-->
<!--          <p>Nội dung: Kích hoạt gói: "<span class="highlight">Khuyến mại 7 ngày</span>"</p>-->
<!--          <p v-if="referralVoucher">Đây là <span class="highlight">Mã sử dụng có điều kiện</span>: </p>-->
<!--          <ul v-if="referralVoucher">-->
<!--            <li>Cần thêm <span class="highlight">02 Người mới</span> áp dụng mã. <br>(“<span class="highlight">Người mới</span>” là người chưa từng Áp dụng mã voucher nào)</li>-->
<!--            <li>Mã có hiệu lực cho Tất cả người dùng đã áp dụng khi điều kiện đạt được.</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </a-modal>


</template>

<script>
  import Default from '@/layout/Default'
  import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
  import IconMessenger from '@/components/icon/IconMessenger'
  import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
  import {applyVoucher} from "@/service/market/MarketService";
  import {copyToClipboard} from "@/helper/StringHelper";
  import {MutationUser} from "@/store/modules/UserModule/mutation";
  import {getPaidUserInformation, getUserPermissionFromRoles} from "@/service/user/UserPermissionService";
  import {findPlanInfo, getTransactionData} from "@/service/payment/PaymentService";
  import {updateProfileUserCurrent} from "@/service/user/UserProfileService";

  export default {
    components: {
      Default,
      IconMessenger
    },
    data() {
      return {
        voucherCode: null,
        isFullVoucherDetail: false,
        referralVoucher: null,
        MESSENGER_ID,
        messageText: null,
        messageStatusText: null,
        isApplyVip: null,
        voucherItem: null
      }
    },
    props: {
      closable: {
        default: true
      }
    },
    async mounted() {
      this.voucherCode = this.$route.query['voucher']
      let voucherInfo = await getTransactionData(this.voucherCode)
      this.voucherItem = findPlanInfo(voucherInfo?.voucher_type)
    },
    beforeDestroy() {
      this.$router.push({query: {'voucher': undefined}})
    },
    methods: {
      async onClickShowPricing() {
        await this.$router.push({name: 'pricing'})
      },
      gotoProfile() {
        this.$router.push({name: 'user_view'})
      },
      onClickCopy(text, tooltipId) {
        let result = copyToClipboard(text)
        if (result) {
          document.getElementById(tooltipId).classList.add('show')
          setTimeout(() => {
            document.getElementById(tooltipId).classList.remove('show')
          }, 700)
        }
      },
      async applyVoucher() {
        let result = await applyVoucher(this.voucherCode)
        switch (result?.status) {
          case 'success':
            // this.$notification['success']({
            //   message: 'Nâng cấp thành công',
            //   description: 'Bạn đã nâng cấp thành công gói cước!',
            // })
            this.isApplyVip = true
            this.messageText = `Chúc bạn trải nghiệm hiệu quả nhé ${this.fullName}`
            if (this.voucherCode === this.promoteVoucher) {
              this.promoteVoucher = null
            }
            // fbTrackingPurchase([{
            //   'id' : 1,
            //   'quantity' : 1,
            //   'price' : 250000
            // }])
            this.voucherCode = ''
            await this.updatePaidUserInfo()
            // this.showPopupUpgradeAdvance = false
            // setTimeout(() => {
            //   location.reload()
            // }, 1000)
            break
          case 'waiting':
            // this.$notification['warning']({
            //   message: 'Áp dụng Voucher thành công',
            //   description: `Cần thêm ${result.remain_user_to_active} Người dùng mới áp dụng để kích hoạt Voucher`,
            // })
            this.messageStatusText = 'Áp dụng Voucher thành công'
            this.messageText = `Cần thêm ${result.remain_user_to_active} Người dùng mới áp dụng để kích hoạt Voucher`
            this.showPopupUpgradeAdvance = false
            this.voucherCode = ''
            break
          case 'error':
            // this.$notification['error']({
            //   message: 'Nâng cấp chưa thành công',
            //   description: 'Đã xảy ra lỗi! Vui lòng liên hệ với chúng tôi để được giải quyết',
            // })
            this.isApplyVip = false
            this.messageStatusText = 'Xin lỗi,  tài khoản của bạn chưa đủ điều kiện áp dụng voucher  khuyến mại này'
            this.messageText = `Nguyên nhân: ${this.getErrorMess(result.message)}`
        }
      },
      getErrorMess(serverMess) {
        switch (serverMess) {
          case 'this voucher require old beecost users':
            return 'Voucher này hiện chưa áp dụng cho bạn'
          case 'You don\'t have permission to apply this voucher':
            return 'Voucher này hiện chưa áp dụng cho bạn'
          case 'this voucher require new users':
            return 'Voucher này chỉ áp dụng với người dùng mới'
          case 'You have reached your voucher limit':
            return 'Voucher đã hết hạn sử dụng với bạn'
          case 'voucher has reached limit quota':
            return 'Voucher đã được dùng hết'
          case 'voucher has expired':
            return 'Voucher đã hết hạn sử dụng'
          case 'voucher doesn\'t exist':
            return 'Voucher không tồn tại'
          case 'can\'t get user info':
          case 'please insert voucher code':
          default:
            return 'Không xác định'
        }
      },
      async onClickChatFacebook() {
        window.open(`https://messenger.com/t/${MESSENGER_ID}`, '_blank')
      },
      async updatePaidUserInfo() {
        let voucherInformation = await getPaidUserInformation()
        if (!voucherInformation) {
          return
        }
        const expirationDate = new Date(voucherInformation.end_query_time)
        const isExpired = new Date() - expirationDate > 0
        let freeSearchCount = voucherInformation.remain_free_search_count
        freeSearchCount = freeSearchCount && typeof freeSearchCount === 'number'
          ? freeSearchCount
          : 0
        this.$store.dispatch(`UserModule/${MutationUser.setIsExpired}`, isExpired)
        this.$store.dispatch(`UserModule/${MutationUser.setExpirationDate}`, expirationDate)
        this.$store.dispatch(`UserModule/${MutationUser.freeSearchCount}`, freeSearchCount)
        const response = await updateProfileUserCurrent()
        let permissionName = getUserPermissionFromRoles(response?.roles)?.name
        this.$store.commit(`UserModule/${MutationUser.permissionAvailableName}`, permissionName)
        this.$store.commit(`UserModule/${MutationUser.permissionPlanName}`, permissionName)
      },
    },
    computed: {
      inactiveVouchers: {
        get() {
          return this.$store.state.UserModule.inactiveVouchers
        }
      },
      fullName: {
        get() {
          return this.$store.state.UserModule.fullName
        }
      },
      promoteVoucher: {
        get() {
          return this.$store.state.UserModule.promoteVoucher
        },
        async set(value) {
          await this.$store.dispatch(`UserModule/${MutationUser.promoteVoucher}`, value)
        }
      },
      showPopupUpgradeAdvance: {
        get() {
          return this.$store.state.GeneralModule.showPopupUpgradeAdvance
        },
        async set(value) {
          await this.$store.dispatch(`GeneralModule/${MutationGeneral.setShowPopupUpgradeAdvance}`, value)
        }
      },
    },
  }
</script>

<style>
.ant-modal-title {
  font-size: 24px;
  color: #ff7227;
}
</style>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}

.size-lg {
  font-size: 16px;
}

.margin-top-40 {
  margin-top: 40px;
}

.padding-x-25 {
  padding: 0 25px;
}
.justify-space-evenly {
  justify-content: space-evenly;
}

.img-logo-beecost {
  width: 100%;
  max-width: 100px;
  height: 100%;
  max-height: 100px;
}

.btn-chat {
  /*margin-top: 20px;*/
  min-height: 40px;
  color: #000000ab;
  font-weight: 500;
  font-size: 17px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  font-weight: 500;
  border: none;

  background: white;
  padding: 5px 5px;
  cursor: pointer;

  .img-login-social {
    width: 20px;
    height: auto;
    margin-right: 10px;
    margin-left: 8px
  }
}

.show-more {
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 4px;
  color: #ff7227;
}

.voucher-information {
  height: 0;
  overflow: hidden;

  &.show-detail {
    height: max-content;
  }

  p {
    margin-top: 8px;
  }

  li {
    margin-top: 8px;
  }
}

.remain-user-to-active {
  font-size: 18px;
  font-weight: 700;
  color: #ff7227;
}

.highlight {
  font-weight: 600;
}

.voucher-code {
  font-size: 16px;
  //font-weight: 700;
  //color: #ff7227;
  line-height: 37px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  margin: 0 8px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  color: #ff7227;
  position: absolute;
  z-index: 1;
  text-align: left;
  opacity: 0;
  transition: opacity 0.5s;
  left: 0;
  bottom: -100%;
  padding-top: 10px;
}

.tooltip .tooltiptext#popup_upgrade_tooltip {
  left: 100%;
  bottom: 0;
  padding-left: 10px;
}

.tooltip .tooltiptext.show {
  visibility: visible;
  opacity: 1;
}

.btn-login {
  /*margin-top: 20px;*/
  //min-height: 60px;
  color: #000000ab;
  font-weight: 500;
  font-size: 17px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  font-weight: 500;
  border: none;
  background: white;
  padding: 10px 10px;
  cursor: pointer;

  .img-login-social {
    width: 20px;
    margin-right: 10px;
  }
}

.message-container {
  p {
    margin-top: 12px;

    &.status {

      &.error {
        color: #ee0033;
      }

      &.success {
        font-weight: 600;
        color: rgb(9, 109, 217);
      }
    }
  }
}
</style>

<template>
  <div class="notification-banner">
    <div class="notification-banner-wrap">
        <span class="notification-text">
          <span>Thông báo</span>: Từ thứ hai ngày 12/04/2021, Beecost bắt đầu triển khai bản thu phí với toàn bộ khách hàng
          (Bạn được tặng 1 voucher 7 ngày sử dụng - <span>Áp dụng ngay</span> )
        </span>
    </div>
  </div>
</template>

<script>
import {URL_DOWNLOAD_VAONET} from "@/config/ConfigEndpoint";

export default {
  name: "BannerAnnouncing",
  data() {
    return {
      URL_DOWNLOAD_VAONET: URL_DOWNLOAD_VAONET
    }
  }
}
</script>

<style lang="scss" scoped>
  .notification-banner {
    height: 30px;
    border-bottom: 1px solid #eee;
    background: #eee;
    width: 100%;

    .notification-banner-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-left: 53px;
      margin: 0 auto;
      width: 100%;

      @media screen and (min-width: 1024px) {
        max-width: 960px;
      }

      @media screen and (min-width: 1216px) {
        max-width: 1152px;
      }

      .notification-text {
        font-size: 18px;
      }

      .sub-notification-text {
        font-size: 12px;
      }
    }
  }
</style>

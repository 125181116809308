<template>
  <a-layout id="bee-default-layout">
    <BeeHeader/>

    <a-layout>
      <SidebarMenuLeft/>

      <a-layout-content
        :style="{ marginLeft: contentMarginLeft+'px', minHeight: '280px'}"
      >
        <slot></slot>
      </a-layout-content>

    </a-layout>

    <BeeFooter/>

  </a-layout>
</template>

<script>
  import BeeHeader from '../components/partial/header/BeeHeader'
  import BeeFooter from '../components/partial/footer/BeeFooter'
  import SidebarMenuLeft from '../components/partial/sidebar/SidebarMenuLeft'
  import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";

  export default {
    components: {
      BeeHeader,
      BeeFooter,
      SidebarMenuLeft
    },
    data() {
      return {}
    },
    async mounted() {
    },
    computed: {
      contentMarginLeft: {
        get() {
          let collapsed = this.$store.getters[`SidebarModule/${MutationSidebar.isCollapsed}`]
          if (collapsed === true) {
            return '80'
          } else {
            return '200'
          }
        }
      }
    }
  }
</script>


<style lang="scss">
  /*@import "../assets/app/app.scss";*/
  @import "../assets/styles/animation/shake-bell";

</style>

<template>
  <a-modal title=""
           :closable="true"
           :maskClosable="false"
           :footer="null"
           v-model="showPopupPermissionNotification"
           :width="600">
    <div class="page container-bg-white">
      <div class="rows is-mobile">
        <h1 class="title">
          <a-icon type="notification" />
          <span class="title-text">Tài khoản sử dụng Market</span>
        </h1>
        <div class="content-container">
          <div class="content" :style="`--url-background: url(${urlSticker})`" style="height: 170px">
            <span class="title-text">Bạn đang dùng <strong>{{ accountName }}</strong>:</span>
            <ul style="list-style: none">
<!--              <li><span style="margin-right: 16px">-</span> Miễn phí 05 lượt phân tích hàng ngày</li>-->
              <li v-if="freeSearchCount < 1000"><span style="margin-right: 16px">-</span> Bạn còn <span style="color: #ee0033">{{freeSearchCount}}</span> lượt phân tích cho hôm nay </li>
              <li v-if="freeSearchCount < 1000"><span style="margin-right: 16px">-</span> Bạn còn <span style="color: #ee0033">{{freeSearchCountPerWeek}}</span> lượt phân tích cho tuần này </li>
              <li v-if="remainProductDownloadCountPerWeek < 10000"><span style="margin-right: 16px">-</span> Bạn còn <span style="color: #ee0033">{{remainProductDownloadCountPerWeek}}</span> dòng download cho tuần này </li>
            </ul>
<!--            <span class="title-text"><strong class="text-highlight">Tài khoản Nâng cao</strong> (<strong>bạn cần nâng cấp</strong>):</span>-->
<!--            <ul style="list-style: none">-->
<!--              <li style="display: flex; flex-direction: row">-->
<!--                <a-icon type="check" class="text-highlight" style="margin-right: 8px; margin-top: 4px" />-->
<!--                <div>Phân tích theo từ khoá <br>-->
<!--                  <span v-if="iQueries && iQueries.length"> (ví dụ: <i class="text-highlight">{{iQueries.slice(0,3).join(', ')}}, ...</i>)</span>-->
<!--                  <span v-else> (ví dụ: <i class="text-highlight">Giày Sneaker, Son dưỡng môi, ...</i>)</span>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Không giới hạn lượt phân tích</li>-->
<!--              <li><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Download danh sách sản phẩm</li>-->
<!--              <li><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Sử dụng phân tích nâng cao</li>-->
<!--              <li><a-icon type="check" class="text-highlight" style="margin-right: 8px" /> Liên tục cập nhật tính năng mới</li>-->
<!--            </ul>-->
          </div>
          <div class="button-action" v-if="!isHigherPriorityAccount(PERMISSION.market_basic.priority)">
            <a-button @click="onClickBtnPricing" size="large" type="primary">Nâng cấp Tài Khoản</a-button>
          </div>
        </div>
<!--        <iframe v-if="showPopupPermissionNotification" width="560" height="315" src="https://www.youtube.com/embed/wsTkZTMWWY8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {MutationSearchFilter} from "@/store/modules/SearchFilterModule/mutation";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {mixinStoreGetterPermission, mixinStoreGetterUser} from "@/mixin/store/MixinUser";
import {PERMISSION} from "@/constant/general/PermissionConstant";

export default {
  name: "PopupPermissionNotification",
  data() {
    return {
      urlSticker: getUrlGPhotoSize(
        'https://lh3.googleusercontent.com/pw/ACtC-3eS5orE9WC3cR5DhVAYSLeJH7Yv7Nut8AE8jQz3Rz5DW2y3zMaoJclpaOsTtYGofOEHT1qbL2Ms3C4Hw_GjT3roSD_t3P0t-QLt1pefA6YC3K5JU95DqcUpQftCEz-8iP_wFTqXls6_doL5U3UDm8bI',
          '256'
      ),
      PERMISSION: PERMISSION
    }
  },
  computed: {
    iQueries: {
      get() {
        return this.$store.state.SearchFilterModule.iQueries
      },
      async set(value) {
        await this.$store.commit(`SearchFilterModule/${MutationSearchFilter.iQueries}`, value)
      }
    },
    showPopupPermissionNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupPermissionNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, value)
      }
    },
  },
  mixins: [
    mixinStoreGetterUser,
    mixinStoreGetterPermission
  ],
  methods: {
    async onClickBtnPricing () {
      await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
      // this.$router.push({name: 'pricing'})
      this.showPopupPermissionNotification = false
    }
  },
}
</script>

<style lang="scss" scoped>
  .title-text {
    margin-left: 4px;
  }

  .content-container {
    margin-bottom: 20px;

    .content {
      margin-bottom: 24px;
      margin-left: 22px;
      background-image: var(--url-background);
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 160px;

      .main-content {
        font-size: 20px;
        color: $--color-primary;
      }

      .sub-content {
        width: 100%;
        text-align: end;

        a {
          color: gray;
          text-decoration: underline;
        }
      }
    }

    .button-action {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      button {
        margin-left: 10px;
      }

      .sub-text-btn {
        font-size: 10px;
      }
    }
  }

  .text-highlight {
    color: $--color-primary;;
  }
</style>

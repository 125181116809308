import { render, staticRenderFns } from "./PopupNotification.vue?vue&type=template&id=6166e584&scoped=true&"
import script from "./PopupNotification.vue?vue&type=script&lang=js&"
export * from "./PopupNotification.vue?vue&type=script&lang=js&"
import style0 from "./PopupNotification.vue?vue&type=style&index=0&id=6166e584&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6166e584",
  null
  
)

export default component.exports
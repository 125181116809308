<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 1024 1024" style="enable-background:new 0 0 1024 1024;" xml:space="preserve">
    <g id="Messenger_4_">
	<linearGradient id="Bubble_Solid_1_" gradientUnits="userSpaceOnUse" x1="512" y1="11.5" x2="512"
                  y2="1005.4999">
		<stop offset="0" style="stop-color:#00B2FF"/>
    <stop offset="1" style="stop-color:#006AFF"/>
	</linearGradient>
      <path id="Bubble_Solid_3_" class="st0" d="M512,11.5c-280,0-497,205.1-497,482.1c0,144.9,59.4,270.1,156.1,356.6
		c8.1,7.3,13,17.4,13.4,28.3l2.7,88.4c0.9,28.2,30,46.5,55.8,35.2l98.6-43.5c8.4-3.7,17.7-4.4,26.5-2
		c45.3,12.5,93.6,19.1,143.9,19.1c280,0,497-205.1,497-482.1S792,11.5,512,11.5z"/>
      <path id="Bolt_4_" class="st1" d="M213.6,634.6l146-231.6c23.2-36.8,73-46,107.8-19.9l116.1,87.1c10.7,8,25.3,7.9,35.9-0.1
		l156.8-119c20.9-15.9,48.3,9.2,34.2,31.4L664.5,614c-23.2,36.8-73,46-107.8,19.9l-116.1-87.1c-10.7-8-25.3-7.9-35.9,0.1L247.8,666
		C226.9,681.9,199.5,656.8,213.6,634.6z"/>
</g>
</svg>
</template>

<script>
  export default {}

</script>

<style lang="scss" scoped>
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: url(#Bubble_Solid_1_);
  }

  .st1 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #FFFFFF;
  }
</style>

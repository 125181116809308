<template>

  <a-layout-footer style="text-align: center" :style="isDesktop ? `${collapsed ? 'padding-left: 130px' : 'padding-left: 250px'}` : 'padding: 0px'">
    <div class="content">
      <div class="flex" :class="isMobile ? 'flex-col p-4' : 'flex-row'">
        <div class="text-left" :class="isMobile ? 'w-full' : 'w-1/2'">
          <div class="mb-3">
            <a style="color: unset" href="/">
              <BeeMetricLogoSlogan style="height: 55px; transform: translateY(-2px); width: auto;" />
            </a>
          </div>
          <p class="padding-right-25"><strong>EcomViet Metric</strong> là công cụ phân tích dữ liệu bán hàng thị trường TMĐT Shopee, Tiki, Lazada, Sendo, giúp người bán <strong>phân tích volume thị trường, tìm kiếm sản phẩm bán chạy và theo dõi đối thủ.</strong></p>
          <!-- <a href="http://online.gov.vn/Home/WebDetails/87638" rel="nofollow" target="_blank">
            <img src="https://lh3.googleusercontent.com/bZLHKWWcqriZqy2n9V50TR7q8le3DwZ3wj-gLM7CV4sJgoluQmboRTsXjvwqBfiSX_wevs9t9uzZYK6hdyhUorsv9TmylqT83NVsdA63SwIS-UTaBPlgyi5DIpU1iJwq7EX6Q2_SnA" alt="logo-BCT" :width="isDesktop ? '200px' : '100px'" height="auto" class="mt-4">
          </a> -->
        </div>
        <div class="flex justify-end w-full" :class="isMobile ? 'flex-col' : 'flex-row'">
          <div style="display: block" class="text-left" :style="isDesktop ? 'max-width: 350px; margin-right: 50px; margin-left: 20px' : ''">
            <p class="uppercase flex items-end font-semibold" style="height: 46px">Về {{BRAND_NAME}} </p>
            <article>
              <p class="mt-1"><a href="/chinh-sach-bao-ve-thong-tin-ca-nhan-khach-hang" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Chính sách bảo vệ thông tin cá nhân khách hàng</a></p>
              <p class="mt-2"><a href="/quy-dinh-dung-thu-dich-vu" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Quy định dùng thử dịch vụ</a></p>
              <p class="mt-2"><a href="/hinh-thuc-thanh-toan" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Hình thức thanh toán</a></p>
              <p class="mt-2"><a href="/chinh-sach-bao-hanh-va-bao-tri" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Chính sách bảo hành và bảo trì</a></p>
              <p class="mt-2"><a href="/chinh-sach-doi-tra-san-pham" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Chính sách đổi trả sản phẩm</a></p>
              <p class="mt-2"><a href="/chinh-sach-dat-hang" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Chính sách đặt hàng</a></p>
              <p class="mt-2"><a href="/dieu-kien-giao-dich-chung" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_self">Điều kiện giao dịch chung</a></p>
            </article>
          </div>
          <div class="text-left" style="max-width: 400px">
            <p class="uppercase flex items-end font-semibold" style="height: 46px">Liên hệ</p>
            <article>
              <p class="mt-1">Tên đơn vị: Trung tâm Phát triển thương mại điện tử - EcomViet</p>
              <p class="mt-1">Tên dự án: EcomViet Metric</p>
              <p class="mt-1">Địa chỉ: 25 P. Ngô Quyền, Tràng Tiền, Hoàn Kiếm, Hà Nội</p>
              <p class="mt-1">Điện thoại: 024 2220 5507</p>
              <p class="mt-1">Email: metric@ecomviet.vn</p>
              <p class="mt-1">Đơn vị phối hợp: Công ty cổ phần Khoa học Dữ liệu</p>
            </article>
          </div>
        </div>
      </div>
<!--    <p>-->
<!--      <a href="/terms" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_blank">Điều khoản sử dụng</a>-->
<!--      - -->
<!--      <a href="/privacy" style="color: unset" class="cursor-pointer hover:text-primary-900" target="_blank">Chính sách bảo mật</a>-->
<!--    </p>-->
  </div>
  </a-layout-footer>

</template>
<script>

import {BRAND_NAME, SOLOGAN} from "@/constant/general/GeneralConstant";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";
import BeeMetricLogoSlogan from "@/assets/logo/BeeMetricLogoSlogan";

  export default {
    components: {
      BeeMetricLogoSlogan,
    },
    data() {
      let isMobile = window.innerWidth < 600
      return {
        BRAND_NAME: BRAND_NAME,
        SOLOGAN: SOLOGAN,
      }
    },
    computed: {
      collapsed: {
        get() {
          return this.$store.getters[`SidebarModule/${MutationSidebar.isCollapsed}`]
        }
      },
    },
    mixins: [
      mixinStoreGetterGlobal
    ]
  }
</script>
<style lang="scss">


</style>

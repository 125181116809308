<template>
  <a-modal
    :closable="true"
    :maskClosable="false"
    :footer="null"
    :visible="isShowPopup"
    @cancel="onClickCancel"
    :width="isDesktop ? 500 : 340"
    wrapClassName="popup-signup-lead-form"
  >
    <div class="form" v-if="popupState === POPUP_STATES.FORM">
      <div class="header flex items-center md:items-end mb-4">
        <img :src="urlSticker" alt="beecost-lead-form" class="bee-image"/>
        <h3 class="text-center mt-5 md:mt-0 popup-title">
          Nhận đăng ký tư vấn trực tiếp để <br v-if="isDesktop"/>
          Nâng cấp tài khoản
        </h3>
      </div>
      <form @submit="handleSubmit">
        <div class="flex mb-4 flex-col md:flex-row">
          <div class="mb-4 md:mb-0 md:mr-2">
            <label for="fullName-input">
              Họ và tên <span style="color: #ef4444">*</span>
            </label>
            <a-input
              id="fullName-input"
              name="fullName"
              v-model="formData.fullName"
              placeholder=""
              class="mt-2"
              @blur="onBlur"
              :class="{ error: !!errors.fullName }"
            />
            <div v-if="errors.fullName" class="error-message mt-2">
              Vui lòng nhập Họ và tên.
            </div>
          </div>
          <div class="md:ml-2">
            <label for="phone-input">
              Số điện thoại <span style="color: #ef4444">*</span>
            </label>
            <a-input
              id="phone-input"
              name="phone"
              v-model="formData.phone"
              placeholder=""
              class="mt-2"
              @blur="onBlur"
              :class="{ error: !!errors.phone }"
            />
            <div v-if="errors.phone" class="error-message mt-2">
              Vui lòng nhập Số điện thoại.
            </div>
          </div>
        </div>
        <div class="mb-4">
          <label for="company-input">Công ty</label>
          <a-input
            id="company-input"
            name="company"
            v-model="formData.company"
            placeholder=""
            class="mt-2"
          />
        </div>
        <div
          class="flex items-center mb-6"
          style="justify-content: space-between"
        >
          <div class="phone-contact text-xs md:text-base">
            Hotline: <span>033-806-2221</span>
          </div>
          <a-button
            type="primary"
            html-type="submit"
            size="large"
            style="font-size: 16px; font-weight: 700"
            :loading="loading"
            v-if="isDesktop"
          >
            Đăng ký nhận tư vấn
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            size="small"
            :loading="loading"
            style="font-size: 14px; font-weight: 500"
            v-else
          >
            Đăng ký tư vấn
          </a-button>
        </div>
        <div class="flex mt-2 text-base" style="justify-content: flex-end">
          Hoặc tới trang
          <a
            target="_blank"
            class="ml-1 font-medium"
            style="color: #2e7cf6; text-decoration: underline"
            @click="onClickPricing"
          >
            Thanh toán gói online
          </a>
        </div>
      </form>
    </div>
    <div
      class="submited"
      :class="POPUP_STATES.SUCCESSFULLY"
      v-if="popupState === POPUP_STATES.SUCCESSFULLY"
    >
      <img src="@/assets/img/upgradeAccountPopup/successfullyIcon.svg"/>
      <h3>Đã gửi thông tin thành công</h3>
      <p>Chúng tôi sẽ liên hệ tới bạn sớm nhất.</p>
    </div>
    <div
      class="submited"
      :class="POPUP_STATES.FAILURE"
      v-if="popupState === POPUP_STATES.FAILURE"
    >
      <img src="@/assets/img/upgradeAccountPopup/failureIcon.svg"/>
      <h3>Thất bại</h3>
      <p class="mb-6">Thông tin chưa được gửi thành công. Vui lòng thử lại.</p>
      <a-button
        type="primary"
        size="large"
        @click="popupState = POPUP_STATES.FORM"
        v-if="countError < MAXIMUM_TRY_TIMES"
      >
        Thử lại
      </a-button>
      <a-button
        class="messenger-button"
        size="large"
        v-else
        @click="contactFacebook"
      >
        <img
          src="@/assets/img/upgradeAccountPopup/messengerIcon.svg"
          class="mr-2"
        />
        Liên hệ hỗ trợ
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import {mixinStoreGetterUser} from "@/mixin/store/MixinUser";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
import {apiCreateLead} from "@/api/lead/ApiLead";
import {StickerConstants} from "@/constant/beecost/BeeCostResourceConstant";
import {getUrlGPhotoSize} from "@/helper/GPhotoHelper";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";

const POPUP_STATES = {
  FORM: "form",
  SUCCESSFULLY: "successfully",
  FAILURE: "failure",
};

const MAXIMUM_TRY_TIMES = 3;

export default {
  name: "PopupSignUpLeadForm",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const urlSticker = getUrlGPhotoSize(StickerConstants.introduction, "256");
    return {
      formData: {
        fullName: "",
        phone: "",
        company: "",
      },
      requiredFields: ["fullName", "phone"],
      popupState: POPUP_STATES.FORM,
      loading: false,
      errors: {},
      countError: 0,
      POPUP_STATES,
      MAXIMUM_TRY_TIMES,
      urlSticker,
    };
  },
  computed: {
    isShowPopup: {
      get() {
        return !!this.$store.getters[`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupSignUpLeadForm}`]
      },
      async set(value) {
        await this.$store.commit(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.setShowPopupSignUpLeadForm}`, value)
      }
    },
  },
  watch : {
    isShowPopup(value) {
      // reset form data when popup is opened
      if (value) {
        this.formData = {
          fullName: this.fullName,
          phone: "",
          company: "",
        };
        this.popupState = POPUP_STATES.FORM;
        this.countError = 0;
        this.errors = {};
        this.loading = false;
      }
    }
  },
  methods: {
    onClickOk() {
      this.showPopup = "";
      this.$emit("ok");
    },
    onClickCancel() {
      this.$emit("cancel");
    },
    onBlur(event) {
      this.validate(event.target.name);
    },
    validate(fieldName) {
      this.errors = {};
      if (fieldName) {
        this.errors[fieldName] = this.requiredFields.includes(fieldName) && !this.formData[fieldName];
      } else {
        this.errors = this.requiredFields.reduce((errors, field) => {
          errors[field] = !this.formData[field];
          return errors;
        }, {});
      }
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.formData.fullName || !this.formData.phone) {
        this.validate();
        return;
      }
      try {
        this.loading = true;
        const response = await apiCreateLead({
          name: this.formData.fullName,
          phone: this.formData.phone,
          email: this.userProfile?.email,
          organization_name: this.formData.company,
          note: window.location.href,
          label_init: "Nóng",
          source_name: "Website",
          campaign: "Đăng ký dùng thử",
        });
        this.loading = false;

        if (
          response &&
          (response.status === "created" || response.status === "duplicate")
        ) {
          this.popupState = POPUP_STATES.SUCCESSFULLY;
        } else {
          this.countError += 1;
          this.popupState = POPUP_STATES.FAILURE;
        }
      } catch (error) {
        console.log(error);
        this.countError += 1;
        this.popupState = POPUP_STATES.FAILURE;
        this.loading = false;
      }
    },
    contactFacebook() {
      window.open(`http://messenger.com/t/${MESSENGER_ID}`, "_blank");
    },
    onClickPricing() {
      this.$emit("cancel");
      this.$router.push({
        name: "pricing",
      });
    },
  },
  mixins: [mixinStoreGetterUser, mixinStoreGetterGlobal],
};
</script>

<style lang="scss" scoped>
.form {
  label {
    color: #525252;
  }

  input[type="text"] {
    height: 48px;
    border-radius: 8px;

    &:focus,
    &:hover {
      border-color: #3b82f6;
    }

    &:focus {
      box-shadow: none;
    }

    &.error {
      color: #ef4444;
      border-color: #ef4444;

      &:focus {
        color: unset;
      }
    }
  }

  button {
    border-radius: 8px;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 24px;
    height: 40px;
    background: #ff7227;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.85);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  .phone-contact {
    font-style: italic;
    font-weight: 400;

    color: #737373;

    span {
      font-weight: bold;
      color: #ff7227;
    }
  }

  .error-message {
    color: #ef4444;
  }
}

.submited {
  text-align: center;

  h3 {
    margin-top: 16px;
    font-weight: 600;
    font-size: 20px;
  }

  &.successfully {
    padding: 36px 0;

    h3 {
      color: #22c55e;
    }
  }

  &.failure {
    padding: 32px 0;

    button {
      border-radius: 8px;

      &.messenger-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        margin: auto;
        box-shadow: 2px 3px 5px rgba(78, 78, 78, 0.08);
        border-radius: 4px;
      }
    }

    h3 {
      color: #ef4444;
    }
  }
}

.popup-title {
  font-size: 16px;
}

.bee-image {
  width: 47px;
  height: 56px;
  margin-right: 18px;
}

@media (min-width: 576px) {
  .popup-title {
    font-size: 22px;
  }
  .bee-image {
    width: 76px;
    height: 90px;
  }
  .form {
    button {
      padding: 12px 28px;
      height: 43px;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>

<style lang="scss">
.popup-signup-lead-form .ant-modal-body {
  padding: 16px;
}

@media (min-width: 576px) {
  .popup-signup-lead-form .ant-modal-body {
    padding: 24px;
  }
}
</style>
